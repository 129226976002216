import React from "react"
import { Helmet } from "react-helmet"
import {
  HeroBanner,
  Footer
} from '../components'
import {
  H1,
  TwoColumnContainer
} from '../styles/components'
import Fade from 'react-reveal/Fade'
import service1 from '../images/service_1.jpg'
import service2 from '../images/service_2.jpg'
import service3 from '../images/service_3.jpg'
import service4 from '../images/service_4.jpg'

export default function HomePage() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Turnkey Trading & Contracting</title>
        <link rel="canonical" href="https://turnkey-egy.com/" />
      </Helmet>
      <HeroBanner />
      <H1 pt="100px" className="indexHeader">What We Offer</H1>
      <hr className="divider"></hr>
      <Fade bottom>
        <TwoColumnContainer className="service">
          <img src={service1} alt="hospitality" />
          <div>
            <h1>Your one-stop shop for fit out design.</h1>
            <p>
              Our team specializes in all aspects of fit out construction and design
              and can see your project through from beginning to end.
            </p>
          </div>
        </TwoColumnContainer>
      </Fade>
      <Fade bottom>
        <TwoColumnContainer className="service" reverse={true}>
          <img src={service2} alt="commercial" />
          <div>
            <h1>We bring a diversity and depth of experience.</h1>
            <p>
              Our scope of work includes commercial, residential, hospitality,
              and office spaces, and more.
            </p>
            <p>
              Fit out would include the following: interior finishes, furniture and equipment,
              fixtures, and MEP works.
            </p>
          </div>
        </TwoColumnContainer>
      </Fade>
      <Fade bottom>
        <TwoColumnContainer className="service">
          <img src={service3} alt="offices" />
          <div>
            <h1>Our experts can help bring your vision to life.</h1>
            <p>
              We offer guidance to turn your ideas into concrete plans 
              as well as fulfilling ready to execute designs.
            </p>
          </div>
        </TwoColumnContainer>
      </Fade>
      <Fade bottom>
        <TwoColumnContainer className="service" reverse={true}>
          <img src={service4} alt="residential" />
          <div>
            <h1>Our priority is to exceed your expectations.</h1>
            <p>
              We value building long-lasting relationships with our clients
              by providing high quality, cost-effective solutions.
            </p>
          </div>
        </TwoColumnContainer>
      </Fade>
      <Footer />
    </>
  )
}
